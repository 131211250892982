import { Model, Store } from 'store/Base';
import { observable } from 'mobx';
import { Company } from './Company';
import { ProjectModuleStore } from './ProjectModule';
import { MilestoneStore } from './Milestone';
import { Team } from './Team';
import { User } from './User';


export class Project extends Model {
    static backendResourceName = 'project';

    static omitFields = ['fullName'];

    @observable id = null;
    @observable name = '';

    @observable fullName = '';
    @observable quotationLink = '';

    @observable company = this.relation(Company);

    @observable projectModules = this.relation(ProjectModuleStore)

    @observable milestones = this.relation(MilestoneStore);

    @observable team = this.relation(Team);

    @observable projectLead = this.relation(User);

    // annotations for the budget of this project
    @observable hasActiveBudget = false;

    @observable monthlyBudgetHoursMin: null | number = null;
    @observable monthlyBudgetHoursMax: null | number = null;
    @observable fixedBudgetHours: null | number = null;

    @observable monthlyTimeSpend: null | number = null;
    @observable fixedTimeSpend: null | number = null;

    @observable milestonesCompletedCount = 0;
    @observable milestonesWipCount = 0;
    @observable milestonesNotStartedCount = 0

}

export class ProjectStore extends Store {
    Model = Project;
    static backendResourceName = 'project';
}
